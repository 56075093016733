* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Noto Sans";
}

@tailwind base;
@tailwind components;
@tailwind utilities;
